import { Box, Button as MUIButton, styled } from "@mui/material";
import { useTranslate } from "react-admin";

const Container = styled(Box)`
  background: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Button = styled(MUIButton)`
  width: 100%;
`;

interface FiltersActionsProps {
  applyFilters: () => void;
  resetFilters: () => void;
}

export const FiltersActions: React.FC<FiltersActionsProps> = (props) => {
  const { applyFilters, resetFilters } = props;
  const translate = useTranslate();

  return (
    <Container>
      <Button variant="contained" onClick={applyFilters}>
        {translate("shared.filters.apply")}
      </Button>
      <Button variant="outlined" onClick={resetFilters}>
        {translate("shared.filters.reset")}
      </Button>
    </Container>
  );
};
