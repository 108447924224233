import React, { Dispatch, SetStateAction } from "react";

import { FormGroup } from "@mui/material";
import { CollapsibleSection } from "ui/molecules/collapsible-section/CollapsibleSection";
import { RangeGroup, RangeNumber } from "ui/molecules/range-group/RangeGroup";

import { IRealEstatesFilters } from "../RealEstateFilters.types";
import { RangeKeysOfRealEstateFilters } from "./FiltersRangeGroup.types";

export interface RangeGroupProps {
  filters: IRealEstatesFilters;
  setFilters: Dispatch<SetStateAction<IRealEstatesFilters>>;
  source: RangeKeysOfRealEstateFilters;
  label: string;
}

export const FiltersRangeGroup: React.FC<RangeGroupProps> = (props) => {
  const { filters, setFilters, source, label } = props;

  const onChange = (value: RangeNumber) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [source]: value,
    }));
  };

  const value = {
    max: filters?.[source]?.max,
    min: filters?.[source]?.min,
  };

  return (
    <CollapsibleSection label={label} isDefaultExpanded={!!filters[source]}>
      <CollapsibleSection.GroupSpacer>
        <FormGroup>
          <RangeGroup value={value} onChange={onChange} testId={source} />
        </FormGroup>
      </CollapsibleSection.GroupSpacer>
    </CollapsibleSection>
  );
};
