import { LinearProgress, styled } from "@mui/material";
import { DataGrid as MUIDataGrid, DataGridProps } from "@mui/x-data-grid";
import { useDataGridLanguage } from "hooks/use-datagrid-language/useDataGridLanguage";
import { DataGridPagination } from "ui/molecules/data-grid-pagination/DataGridPagination";
import { TableToolbar } from "ui/molecules/table-toolbar/TableToolbar";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { useListContext } from "react-admin";

const DataGrid = styled(MUIDataGrid)`
  background-color: white;

  && {
    .MuiDataGrid-columnHeaderTitle:first-letter,
    .MuiDataGrid-columnsPanelRow .MuiFormControlLabel-label:first-letter {
      text-transform: uppercase;
    }

    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-columnSeparator--sideRight {
      display: none !important;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold !important;
    }

    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
`;

export const DataTable: React.FC<DataGridProps> = ({
  rows,
  columns,
  onCellClick,
  ...rest
}) => {
  const localeText = useDataGridLanguage();

  const { setSort, isFetching, total, page, setPage, perPage } =
    useListContext();

  const onChangePage = (
    _: React.ChangeEvent<unknown>,
    nextPage: number
  ): void => {
    setPage(nextPage);
  };

  const Pagination = (
    <DataGridPagination
      currentPage={page}
      onChangePage={onChangePage}
      totalRecords={total}
      perPage={perPage}
    />
  );

  const handleSortModelChange = ([sortItem]: GridSortModel) => {
    if (sortItem) {
      setSort({
        field: sortItem?.field,
        order: sortItem.sort?.toUpperCase() || "",
      });
    } else {
      setSort({
        field: "id",
        order: "asc",
      });
    }
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      density="compact"
      onCellClick={onCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      pagination
      pageSize={perPage}
      disableColumnFilter
      disableColumnMenu
      disableVirtualization
      loading={isFetching}
      localeText={localeText}
      components={{
        LoadingOverlay: LinearProgress,
        Pagination: () => Pagination,
        Toolbar: TableToolbar,
      }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      {...rest}
    />
  );
};
