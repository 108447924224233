import React from "react";

import { styled } from "@mui/material/styles";
import { Box, TextField as MUITextField } from "@mui/material";
import { useTranslate } from "react-admin";

export interface RangeNumber {
  min?: string | number;
  max?: string | number;
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const TextField = styled(MUITextField)`
  flex: 1;
`;

export interface RangeGroupProps {
  value: RangeNumber;
  onChange: (value: RangeNumber) => void;
  testId?: string;
}

export const RangeGroup: React.FC<RangeGroupProps> = (props) => {
  const { value, onChange, testId = "" } = props;
  const translate = useTranslate();

  const updateValue =
    (type: keyof RangeNumber) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const unchangedValueKey = type === "min" ? "max" : "min";

      onChange({
        [type]: event.target.value,
        [unchangedValueKey]: value?.[unchangedValueKey],
      });
    };

  return (
    <Container>
      <TextField
        name="min"
        label={translate("shared.input.min")}
        variant="outlined"
        value={value?.min ?? ""}
        type="number"
        inputProps={{ "data-testid": `min-input${testId}` }}
        onChange={updateValue("min")}
      />
      <TextField
        name="max"
        label={translate("shared.input.max")}
        variant="outlined"
        value={value?.max ?? ""}
        type="number"
        inputProps={{ "data-testid": `max-input${testId}` }}
        onChange={updateValue("max")}
      />
    </Container>
  );
};
