import { css, styled } from "@mui/material/styles";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

const Container = styled(GridToolbarContainer)`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 5px;
  position: relative;
`;

const buttonStyle = css`
  display: inline-flex;
  align-items: flex-start;
`;

const ToolbarColumnsButton = styled(GridToolbarColumnsButton)`
  ${buttonStyle}
`;

const ToolbarDensitySelector = styled(GridToolbarDensitySelector)`
  ${buttonStyle}
`;

export const TableToolbar: React.FC = () => {
  return (
    <Container>
      <ToolbarColumnsButton />
      <ToolbarDensitySelector />
    </Container>
  );
};
