import { AutocompleteValue } from "ui/molecules/autocomplete-input/AutocompleteInput";
import { getObjectKeys } from "utils/object/object.utils";

import type {
  RawGooglePlaceSuggestion,
  FieldError,
} from "./GooglePlacesAutocomplete.types";

export const formatPlacesOptions = (
  places: RawGooglePlaceSuggestion[]
): AutocompleteValue<RawGooglePlaceSuggestion>[] => {
  return places.map((place: RawGooglePlaceSuggestion) => {
    return {
      label: place?.description,
      rawData: place,
      value: place?.description,
    };
  });
};

export const hasError = (error: FieldError): boolean => {
  if (!error) {
    return false;
  }
  return getObjectKeys(error).some((key) => !!error[key]);
};
