import { useState, useCallback, useMemo } from "react";

import { useTranslate, useGetList } from "react-admin";
import {
  AutocompleteInput,
  AutocompleteValue,
} from "ui/molecules/autocomplete-input/AutocompleteInput";
import { capitalizeFirstLetter } from "utils/strings/strings.utils";

import { CityData } from "./RealEstateFilters.types";

interface CitiesAutocompleteProps {
  onSelect: (city: Nullable<AutocompleteValue<CityData>>) => void;
}

export const CitiesAutocomplete: React.FC<CitiesAutocompleteProps> = (
  props
) => {
  const { onSelect } = props;
  const [citySearch, setCitySearch] = useState("");
  const translate = useTranslate();
  const { data } = useGetList("real-estates/cities");

  const handleSelect = (city: Nullable<AutocompleteValue<CityData>>) => {
    onSelect(city);
    setCitySearch("");
  };

  const onInputChange = useCallback((text: string) => setCitySearch(text), []);
  const options = useMemo(
    () =>
      data?.map((option: CityData) => ({
        label: option.id,
        rawData: option,
        value: option.id,
      })) ?? [],
    [data]
  );

  return (
    <AutocompleteInput
      onInputChange={onInputChange}
      onSelect={handleSelect}
      value={{ label: citySearch, value: citySearch }}
      options={options}
      label={capitalizeFirstLetter(translate("shared.address.city"))}
      size="small"
    />
  );
};
