import React from "react";

import { GridRenderCellParams } from "@mui/x-data-grid";
import { PriceField } from "ui/atoms/price-field/PriceField";
import { DateField } from "ui/atoms/date-field/DateField";
import { GridCellRenderer, CellType, TYPE_CELL_TYPE } from "types/mui.types";
import { PercentField } from "ui/atoms/percent-field/PercentField";

import { dateOptions } from "../i18n/i18n.utils";

export const getDateCell = (
  params: GridRenderCellParams
): React.ReactElement => (
  <DateField
    date={params.value}
    options={{ ...dateOptions, year: "numeric" }}
  />
);

export const getPriceCell = ({
  value,
}: GridRenderCellParams): React.ReactElement =>
  value ? <PriceField price={value} /> : <></>;

export const getPercentCell = ({
  value,
}: GridRenderCellParams): React.ReactElement => {
  return value ? <PercentField percent={value} /> : <></>;
};

export const TYPE_CELL: TYPE_CELL_TYPE = {
  [CellType.DATE]: getDateCell,
  [CellType.PRICE]: getPriceCell,
  [CellType.PERCENTAGE]: getPercentCell,
};

export const getCellByType = (
  type: Nullable<CellType>
): GridCellRenderer | undefined => {
  if (!type) {
    return undefined;
  }
  return TYPE_CELL[type];
};
