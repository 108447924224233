import { SyntheticEvent } from "react";

import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";

export interface CheckboxProps {
  checked: boolean;
  onChange: (nextChecked: boolean) => void;
  label: string;
  value: string;
  testId?: string;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { checked, onChange, label, value, testId } = props;

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    nextChecked: boolean
  ) => {
    onChange(nextChecked);
  };

  return (
    <FormControlLabel
      data-testid={testId}
      checked={checked}
      value={value}
      onChange={handleChange}
      control={<MUICheckbox size="small" />}
      label={label}
    />
  );
};
