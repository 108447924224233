import type {
  GridColDef as MUIGridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

export enum CellType {
  DATE = "date",
  PRICE = "price",
  PERCENTAGE = "percentage",
}

export type GridColDefRequiredFields = "headerName" | "field";

export type GridColDef = RequiredPart<
  MUIGridColDef,
  GridColDefRequiredFields
> & {
  cellType?: CellType;
};

export type GridCellRenderer = (
  params: GridRenderCellParams
) => React.ReactNode;

export type TYPE_CELL_TYPE = {
  [n in CellType]: GridCellRenderer;
};
