import { NumberField, useLocaleState } from "react-admin";

export const PriceField = ({
  price,
  ...rest
}: {
  price: number;
}): React.ReactElement => {
  const [locale] = useLocaleState();

  return (
    <NumberField
      {...rest}
      locales={locale}
      options={{
        currency: "EUR",
        minimumFractionDigits: 0,
        style: "currency",
        unitDisplay: "short",
      }}
      source="price"
      record={{ price: Math.round(price) }}
    />
  );
};
