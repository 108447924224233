export const updateCheckboxValue = (
  value: string,
  checkboxesValue?: string[]
): string[] => {
  if (!checkboxesValue) {
    return [value];
  }

  const filteredValue = checkboxesValue.filter(
    (checkboxValue: string) => checkboxValue !== value
  );

  if (filteredValue.length !== checkboxesValue.length) {
    return filteredValue;
  }

  return [...filteredValue, value];
};
