import { useCallback, useState } from "react";

import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, Divider, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileFooter } from "components/mobile-footer/MobileFooter";
import { Button } from "ui/atoms/button/Button";
import {
  HeaderList,
  HeaderListProps,
} from "ui/molecules/header-list/HeaderList";
import { useTranslate } from "react-admin";
import { SearchBar } from "ui/molecules/search-bar/SearchBar";

const SearchBarWrapper = styled("div")`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-top: 17px;
  }
`;

const Container = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "header"
    "main";

  max-height: calc(100vh - 110px);
  height: calc(100vh - 110px);

  .addButton {
    display: none;
  }

  .filterButton {
    display: flex;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden;
  }

  // Tablette
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    .addButton {
      display: flex;
    }
  }

  // PC
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-height: calc(100vh - 80px);
    height: calc(100vh - 80px);

    gap: 24px;
    grid-template-areas:
      "header header"
      "filters main";

    .addButton {
      display: flex;
    }

    .filterButton {
      display: none;
    }
  }
`;

const HeaderContainer = styled(Box)`
  grid-area: header;
  margin-top: 24px;
`;

const Header = styled(HeaderList)`
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-bottom: 15px;
  }
`;

const CloseFilter = styled(Button)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`;

const Filters = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "$openfilter",
})`
  grid-area: filters;
  background-color: white;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 0;

  > div {
    width: 100%;
  }

  transform: translateX(+100%);

  animation: ${({ $openfilter }: { $openfilter: Nullable<boolean> }) =>
      $openfilter === null
        ? ""
        : $openfilter
        ? "drawerSlideRightIn"
        : "drawerSlideRightOut"}
    300ms ease-in forwards;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    position: relative;
    height: 100%;
    max-height: 100%;
    max-width: 250px;
    right: auto;
    z-index: 0;
    transform: none !important;
    border-radius: 4px;
  }
`;

const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$hasSearchBar",
})`
  grid-area: main;
  display: grid;
  row-gap: 24px;
  grid-template-rows: ${({
    $hasSearchBar,
  }: {
    $hasSearchBar: Nullable<boolean>;
  }) => ($hasSearchBar ? "auto 1fr" : "1fr")};
  margin-top: ${({ $hasSearchBar }: { $hasSearchBar: Nullable<boolean> }) =>
    $hasSearchBar ? "0" : "17px"};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0;
  }
`;

type FilterListLayoutType = {
  main: React.ReactElement;
  filters: React.ReactElement;
  filterValues?: Record<string, unknown>;
  searchPlaceholder?: string;
  setFilters?: (
    filters: unknown,
    displayedFilters: unknown,
    debounce?: boolean | undefined
  ) => void;
} & Omit<HeaderListProps, "onFilterClick">;

export const FilteredListLayout: React.FC<FilterListLayoutType> = ({
  main,
  filters,
  onAddClick,
  title,
  addTitle,
  setFilters,
  filterValues,
  searchPlaceholder,
}) => {
  const [openFilter, setOpenFilter] = useState<boolean | null>(null);
  const translate = useTranslate();

  const toggleOpenFilter = useCallback(() => {
    setOpenFilter((prevState) => !prevState);
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer className="header">
          <Header
            title={title}
            addTitle={addTitle}
            onAddClick={onAddClick}
            onFilterClick={toggleOpenFilter}
          />
          <Divider />
        </HeaderContainer>

        <Filters $openfilter={openFilter}>
          <CloseFilter
            onClick={toggleOpenFilter}
            startIcon={<CloseIcon />}
            data-testid="closeButton"
          >
            {translate("ra.action.close")}
          </CloseFilter>
          {filters}
        </Filters>
        <Main $hasSearchBar={!!setFilters}>
          <>
            {!!setFilters && (
              <SearchBarWrapper>
                <SearchBar
                  title={translate("user.search.title")}
                  placeholder={searchPlaceholder}
                  setFilters={setFilters}
                  filterValues={filterValues}
                />
              </SearchBarWrapper>
            )}
            {main}
          </>
        </Main>
      </Container>
      <MobileFooter>
        <Button
          className="addButton"
          data-testid="add-button"
          variant="contained"
          onClick={onAddClick}
          startIcon={<AddIcon />}
        >
          {addTitle}
        </Button>
      </MobileFooter>
    </>
  );
};
