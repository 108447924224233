import React, { useEffect } from "react";

import { useTranslate, useInput } from "react-admin";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  AutocompleteInput,
  AutocompleteValue,
  AutocompleteInputProps,
} from "ui/molecules/autocomplete-input/AutocompleteInput";
import { useGooglePlacesScript } from "hooks/use-google-places-script/useGooglePlacesScript";

import {
  formatPlacesOptions,
  hasError,
} from "./GooglePlacesAutocomplete.utils";
import type { RawGooglePlaceSuggestion } from "./GooglePlacesAutocomplete.types";

interface GooglePlacesAutocompleteProps {
  source: string;
  defaultValue?: string;
  onChange?: (
    place: string,
    rawData: Nullable<RawGooglePlaceSuggestion>
  ) => void;
  size?: AutocompleteInputProps<string>["size"];
  fullWidth?: boolean;
}

export const GooglePlacesAutocomplete: React.FC<
  GooglePlacesAutocompleteProps
> = (props) => {
  const { source, onChange, fullWidth = false, size, defaultValue } = props;
  const { fieldState, field } = useInput({ source });
  const translate = useTranslate();
  const {
    init,
    value,
    setValue,
    suggestions: { data: places },
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      componentRestrictions: {
        country: ["fr", "es", "be"],
      },
    },
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue("");
    }
  }, [defaultValue, setValue]);

  useGooglePlacesScript({
    onLoad: init,
  });

  const selectPlace = (
    newValue: Nullable<AutocompleteValue<RawGooglePlaceSuggestion>>
  ) => {
    if (!newValue) {
      return;
    }

    field.onChange({ street: newValue.value });
    onChange && onChange(newValue.value, newValue.rawData);
  };

  const handleOnBlur = async (
    newValue: Nullable<AutocompleteValue<RawGooglePlaceSuggestion>>
  ) => {
    if (!newValue) {
      return;
    }

    field.onChange({ street: newValue.value });
    onChange && onChange(newValue.value, places[0]);
  };

  const onInputChange = (newValue: string) => {
    setValue(newValue);
  };

  const filterOptions = (
    options: AutocompleteValue<RawGooglePlaceSuggestion>[]
  ) => {
    return options;
  };

  return (
    <AutocompleteInput
      size={size}
      value={{ label: value, value: value }}
      label={translate("shared.location.address")}
      options={formatPlacesOptions(places)}
      onSelect={selectPlace}
      onBlur={handleOnBlur}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      error={
        hasError(fieldState?.error)
          ? translate("shared.location.invalid_address")
          : undefined
      }
      fullWidth={fullWidth}
    />
  );
};
