interface formatNumberParams {
  locale: string;
  value: number;
}

export const formatCurrency = ({ locale, value }: formatNumberParams): string =>
  new Intl.NumberFormat(locale, {
    currency: "EUR",
    maximumFractionDigits: 0,
    style: "currency",
  }).format(value);

export const formatPercent = ({ locale, value }: formatNumberParams): string =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    style: "percent",
  }).format(value / 100);

export const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  month: "numeric",
  year: "2-digit",
};

interface formatDateParams {
  locale: string;
  date: string;
  options?: Intl.DateTimeFormatOptions;
}

export const formatDate = ({
  locale,
  date,
  options = {},
}: formatDateParams): string => {
  return new Date(date).toLocaleString(locale, { ...dateOptions, ...options });
};
