import { GridColDef } from "types/mui.types";

import { getCellByType } from "./renderCells.utils";

export const getColumns = (
  columns: GridColDef[],
  translate: (args: string) => string
): GridColDef[] =>
  columns.map(({ cellType, headerName, renderCell, ...rest }: GridColDef) => ({
    ...rest,
    headerName: translate(headerName),
    renderCell: renderCell || getCellByType(cellType),
  }));
