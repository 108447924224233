import { DateField as RaField, useLocaleState } from "react-admin";

export const DateField = ({
  date,
  options,
  ...rest
}: {
  date: Date | string;
  options?: Intl.DateTimeFormatOptions;
}): React.ReactElement => {
  const [locale] = useLocaleState();
  return (
    <RaField
      {...rest}
      options={options}
      locales={locale}
      source="date"
      record={{ date }}
      showTime={true}
    />
  );
};
