import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add as AddIcon } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslate } from "react-admin";
import { Button } from "ui/atoms/button/Button";

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 10px;
`;

export type HeaderListProps = {
  title: string;
  addTitle: string;
  onAddClick: () => void;
  onFilterClick: () => void;
};

export const HeaderList: React.FC<HeaderListProps> = (props) => {
  const translate = useTranslate();

  const { title, addTitle, onAddClick, onFilterClick, ...rest } = props;
  return (
    <Container {...rest}>
      <Typography variant="h5">{title}</Typography>
      <ButtonContainer>
        <Button
          className="filterButton"
          data-testid="header-filter-button"
          onClick={onFilterClick}
          startIcon={<FilterListIcon />}
        >
          {translate("shared.filter")}
        </Button>

        <Button
          className="addButton"
          data-testid="header-action-button"
          variant="contained"
          onClick={onAddClick}
          startIcon={<AddIcon />}
        >
          {addTitle}
        </Button>
      </ButtonContainer>
    </Container>
  );
};
