import React, { SetStateAction, Dispatch } from "react";

import { FormGroup } from "@mui/material";
import { Checkbox, CheckboxProps } from "ui/molecules/checkbox/Checkbox";
import { CollapsibleSection } from "ui/molecules/collapsible-section/CollapsibleSection";

import { IRealEstatesFilters } from "../RealEstateFilters.types";
import { updateCheckboxValue } from "./CheckboxGroup.utils";

type CheckboxKeysOfRealEstateFilters = keyof PickKeysOfValueType<
  Required<IRealEstatesFilters>,
  string[]
>;

export interface CheckboxGroupProps {
  label: string;
  checkboxes: Omit<CheckboxProps, "onChange">[];
  setFilters: Dispatch<SetStateAction<IRealEstatesFilters>>;
  source: CheckboxKeysOfRealEstateFilters;
  filters: IRealEstatesFilters;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { label, setFilters, source, checkboxes, filters } = props;

  const onChange = (value: string) => {
    setFilters((oldFilters: IRealEstatesFilters) => {
      const a = {
        ...filters,
        [source]: updateCheckboxValue(value, oldFilters?.[source]),
      };
      return a;
    });
  };

  const checkboxesElements = checkboxes.map(
    (checkbox: Omit<CheckboxProps, "onChange">) => (
      <Checkbox
        key={checkbox.label}
        checked={checkbox.checked}
        onChange={() => onChange(checkbox.value)}
        label={checkbox.label}
        value={checkbox.value}
      />
    )
  );

  return (
    <CollapsibleSection label={label} isDefaultExpanded={!!filters[source]}>
      <CollapsibleSection.GroupSpacer>
        <FormGroup>{checkboxesElements}</FormGroup>
      </CollapsibleSection.GroupSpacer>
    </CollapsibleSection>
  );
};
