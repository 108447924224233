import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Translate } from "react-admin";

import { STATUS_COLORS } from "constants/real-estates";

export const renderCellStatus = (
  translate: Translate
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellStatus = (p: GridRenderCellParams): React.ReactElement => {
    if (!p.value) {
      return <></>;
    }

    const value = p.value.toLowerCase();

    const label = translate(`realEstate.status.${value}`);
    return <Chip label={label} size="small" color={STATUS_COLORS[value]} />;
  };

  CellStatus.displayName = "CellStatus";

  return CellStatus;
};
