import { Box as MUIBox, styled } from "@mui/material";
import { Form } from "react-admin";
import { SearchInput as SearchComp } from "ui/atoms/search-input/SearchInput";

export type SearchBarProps = {
  title: string;
  placeholder?: string;
  setFilters?: (
    filters: unknown,
    displayedFilters?: unknown,
    debounce?: boolean | undefined
  ) => void;
  filterValues?: Record<string, unknown>;
};

const Box = styled(MUIBox)`
  width: 100%;
`;

const SearchInput = styled(SearchComp)`
  width: 100%;
`;

export const SearchBar: React.FC<SearchBarProps> = ({
  title,
  placeholder,
  setFilters,
  filterValues,
}) => {
  const defaultValue = filterValues?.search || "";
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilters?.({ ...filterValues, search: e.target?.value || "" }, {}, true);
  };
  return (
    <Box>
      <Form>
        <SearchInput
          source="search"
          label={title}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Form>
    </Box>
  );
};
