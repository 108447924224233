import React, { useState, useMemo } from "react";

import { useTranslate, useGetList } from "react-admin";
import {
  AutocompleteInput,
  AutocompleteValue,
} from "ui/molecules/autocomplete-input/AutocompleteInput";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { HunterData } from "./RealEstateFilters.types";

interface HuntersAutocompleteProps {
  onSelect: (hunter: Nullable<AutocompleteValue<HunterData>>) => void;
}

export const HuntersAutocomplete: React.FC<HuntersAutocompleteProps> = (
  props
) => {
  const { onSelect } = props;
  const [hunterSearch, setHunterSearch] = useState("");
  const translate = useTranslate();
  const { data } = useGetList("real-estates/hunters");

  const handleSelect = (
    hunterData: Nullable<AutocompleteValue<HunterData>>
  ) => {
    onSelect(hunterData);
    setHunterSearch("");
  };

  const onInputChange = (text: string) => setHunterSearch(text);
  const options = useMemo(
    () =>
      data?.map((option: HunterData) => ({
        label: option.name,
        rawData: option,
        value: option.id,
      })) ?? [],
    [data]
  );

  const filterOptions = createFilterOptions({
    stringify: (opt: AutocompleteValue<HunterData>) => opt.label,
  });

  return (
    <AutocompleteInput
      onInputChange={onInputChange}
      onSelect={handleSelect}
      value={{ label: hunterSearch, value: hunterSearch }}
      options={options}
      filterOptions={filterOptions}
      renderOption={(rest, option: AutocompleteValue<HunterData>) => (
        <li {...rest} key={option.value}>
          {option.label}
        </li>
      )}
      label={translate("shared.hunters_label")}
      size="small"
    />
  );
};
