import { TextInput, TextInputProps } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

export const SearchInput: React.FC<TextInputProps> = ({
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <TextInput
      margin="none"
      variant="outlined"
      multiline={false}
      onChange={onChange}
      placeholder={placeholder}
      helperText=""
      resettable
      clearAlwaysVisible
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
