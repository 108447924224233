import { defaultTheme } from "react-admin";

declare module "@mui/material/styles" {
  interface TypeBackground {
    selected: string;
  }
}

export const themeProvider = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    background: {
      ...defaultTheme.palette.background,
      default: "#fafafa",
      selected: "#EDF4FC",
    },
  },
  /*breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },*/
  typography: {
    ...defaultTheme.typography,
    caption: {
      color: "#757575",
    },
  },
};
