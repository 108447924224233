import { useState } from "react";

import { styled } from "@mui/material/styles";
import { Box, Collapse, Typography, ButtonBase } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { GroupSpacer } from "./GroupSpacer";

const Container = styled(Box)`
  & .MuiBox-root {
    padding: 0;
  }
`;

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled(ButtonBase)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  padding: 4px;
  width: 100%;
`;

const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface CollapsibleSectionProps {
  isDefaultExpanded?: boolean;
  label: string;
}

interface ExpandedButtonIconProps {
  $expended?: string;
}

interface CollapsibleSectionSubcomponents {
  GroupSpacer: React.FC<React.PropsWithChildren>;
}

const ExpandButtonIcon = styled(ExpandMore, {
  shouldForwardProp: (prop) => prop !== "$expended",
})<ExpandedButtonIconProps>`
  transform: rotate(${(props) => (props.$expended ? "0deg" : "-90deg")});
`;

export const CollapsibleSection: React.FC<
  React.PropsWithChildren<CollapsibleSectionProps>
> &
  CollapsibleSectionSubcomponents = (props) => {
  const { children, isDefaultExpanded, label } = props;
  const [expended, setExpended] = useState(isDefaultExpanded ?? false);

  const onClick = () => {
    setExpended(!expended);
  };

  return (
    <Container>
      <HeaderContainer>
        <ButtonContainer onClick={onClick}>
          <ExpandButtonIcon $expended={expended ? "true" : undefined} />
          <Label>{label}</Label>
        </ButtonContainer>
      </HeaderContainer>

      <Collapse in={expended}>{children}</Collapse>
    </Container>
  );
};

CollapsibleSection.GroupSpacer = GroupSpacer;
